$grey: #191a1f;
$yellow: #FFE000;
$green: #43CB9D;

$p: 12px;

$easeOutExpo: cubic-bezier(0.190, 1.000, 0.220, 1.000);

.page-not-found {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 100%;

  .go-home:visited {
    color: inherit;
  }

  .go-home {
    display: block;
    cursor: pointer;
    animation: pulseColor 1000ms linear 3100ms forwards;
    text-decoration: none;
    font-weight: 900;
    &:hover,
    &:focus {
      color: $yellow;
    }
    &:active {
      color: $green;
    }
  }

  svg {
    width: 50px;
    height: auto;
  }

  .crack {
    position: relative;
    z-index: 4;
    margin-left: -50px;

    polyline {
      fill: none;
      stroke: $yellow;
      stroke-width: 10px;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: 1649.099;
      stroke-dashoffset: 1649.099;
      animation: drawStroke 1500ms ease-out 500ms forwards;
    }
  }

  >div {
    display: flex;
    align-items: center;
    align-content: center;
    position: relative;
    overflow: hidden;

    svg {
      position: relative;
      z-index: 1;

      polygon {
        fill: $grey;
      }
    }

    span {
      display: block;
      position: relative;
      z-index: 0;
      padding: 0 $p;
      line-height: 1.4;
    }

    &:first-child {
      text-align: right;
      z-index: 1;

      span {
        &:first-child {
          opacity: 0;
          transform: translateX(100%);
          animation: translateLeft 1000ms linear 1250ms forwards;
        }

        &:last-child {
          opacity: 0;
          transform: translateX(100%);
          animation: translateLeft 1000ms linear 1450ms forwards;
        }
      }

      svg {
        polygon {
          animation: removeFill 10ms ease-out 1600ms forwards;
        }
      }
    }

    &:last-child {
      z-index: 0;
      margin-left: -50px;

      span {
        &:first-child {
          opacity: 0;
          transform: translateX(-100%);
          animation: translateRight 1000ms linear 1650ms forwards;
        }

        &:last-child {
          opacity: 0;
          transform: translateX(-100%);
          animation: translateRight 1000ms linear 1850ms forwards;
        }
      }
    }
  }
}

@keyframes drawStroke {
  0% {
    stroke-dashoffset: 1649.099;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes removeFill {
  0% {
    fill: rgba($grey, 1);
  }

  100% {
    fill: rgba($grey, 0);
  }
}

@keyframes pulseColor {
  0% {
    color: white;
  }

  25% {
    color: $green;
  }

  50% {
    color: white;
  }

  75% {
    color: $green;
  }

  100% {
    color: white;
  }
}

/* https://goo.gl/v323yz */
@keyframes translateLeft {
  0% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 150, 0, 0, 1);
    opacity: 1;
  }

  7.61% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 69.561, 0, 0, 1);
  }

  11.41% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 39.355, 0, 0, 1);
  }

  15.12% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 17.801, 0, 0, 1);
  }

  18.92% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 3.02, 0, 0, 1);
  }

  22.72% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -5.661, 0, 0, 1);
  }

  30.23% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -10.852, 0, 0, 1);
  }

  50.25% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -2.282, 0, 0, 1);
  }

  70.27% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.519, 0, 0, 1);
  }

  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
  }
}

/* https://goo.gl/p1Hnde */
@keyframes translateRight {
  0% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -150, 0, 0, 1);
    opacity: 1;
  }

  7.61% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -69.561, 0, 0, 1);
  }

  11.41% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -39.355, 0, 0, 1);
  }

  15.12% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -17.801, 0, 0, 1);
  }

  18.92% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -3.02, 0, 0, 1);
  }

  22.72% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 5.661, 0, 0, 1);
  }

  30.23% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 10.852, 0, 0, 1);
  }

  50.25% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 2.282, 0, 0, 1);
  }

  70.27% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.519, 0, 0, 1);
  }

  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
  }
}
