$primary: #ffe000;

body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  display: block;
  max-width: 100%;
}

ol {
  list-style-type: decimal;
  padding-inline-start: 40px;
  margin: 0;

  li::marker {
    text-indent: inherit !important;
  }

  > li > ol {
    list-style-type: lower-alpha;
  }
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.App {
  .wallet-adapter-button {
    background-color: $primary;
    color: #000;
    padding: 0 16px;
    height: 38px;
    line-height: 38px;

    &.hide-wallet-icon {
      .wallet-adapter-button-start-icon {
        display: none;
      }
    }

    &:not([disabled]):hover {
      background-color: rgba(255, 224, 0, 0.85);
    }

    &.wallet-multi-gradient {
      background: linear-gradient(
        90deg,
        $primary 0%,
        #ff9962 50.52%,
        #947dff 100%
      );

      &:disabled {
        color: #000;
      }
    }
  }
}

#root:not([aria-hidden="true"]) {
  .notistack-SnackbarContainer {
    z-index: 1099 !important;
  }
}

.shimmer {
  --geist-foreground-rgb: 255, 255, 255;

  background: linear-gradient(
    112deg,
    transparent 30%,
    rgba(var(--geist-foreground-rgb), 0.1) 40%,
    rgba(var(--geist-foreground-rgb), 0.3) 50%,
    rgba(var(--geist-foreground-rgb), 0.1) 60%,
    transparent 80%
  );
  position: absolute;
  inset: 0;
  z-index: 20;
  pointer-events: none;
  border-radius: inherit;
  background-size: 200% auto;
  background-position: 0;
  animation: shimmer 10s cubic-bezier(0.8, 0, 0.05, 1) infinite;
}

@keyframes shimmer {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
    background-position: -50%;
  }
  15% {
    opacity: 0.7;
  }
  30% {
    opacity: 0;
    background-position: 150%;
  }
  35% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
