.landing-carousel {
	.swiper-slide {
		height: auto;
	}
	.swiper-pagination{
		position: initial;
		display: flex;
		justify-content: center;
		.swiper-pagination-bullet {
			background: transparent;
			width: auto;
			height: auto;
			margin: 0 !important;
			padding: 8px 4px;
			&::after {
				content: "";
				display: block;
				width: 16px;
				height: 2px;
				border-radius: 2px;
				background: #727476;
			}
		}
	}
	.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
		bottom: 0
	}

	$progressColor: rgba(255,255,255,0.75);
	.autoplay-progress {
		position: absolute;
    right: 6px;
    top: 6px;
    z-index: 10;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: $progressColor;
		font-size: 12px;
		svg {
			--progress: 0;
			stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
			position: absolute;
			left: 0;
			top: 0px;
			z-index: 10;
			width: 100%;
			height: 100%;
			stroke-width: 3px;
			stroke: $progressColor;
			fill: none;
			stroke-dasharray: 125.6;
			transform: rotate(-90deg);
		}
	}
}
