.gradient-loader {
  background:
    conic-gradient(from 180deg at 50% 50%, #FFE000 0deg, #FF9962 180deg, rgba(148, 125, 255, 0.2) 360deg);
  position: relative;
	animation: rotation 1s infinite linear;

  &::before,
  &::after {
    content: '';
    position: absolute;
  }

  &::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transform: "translate(-50%, -50%)";
    border-radius: 80;
  }

  &::after {
    bottom: 0;
    left: 50%;
    background-color: #FFE000;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
