$coin: 20px;
$depth: 2px;
$perspect: 80px;

$color1: #ff9800;
$color2: #f57c00;
$color3: #ffc107;

.coin-wrap {
  transform: perspective($perspect);
  transform-style: preserve-3d;
  display: inline-block;
  filter: drop-shadow(0 4px 1px #212121);
}
.coin {
  position: relative;
  transform-style: preserve-3d;
  width: $coin;
  height: $coin;
  animation: coin 3s cubic-bezier(0.3, 2, 0.4, 0.8) infinite both;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: calc($coin / 2) - $depth;
    top: 0;
    width: $depth;
    transform: rotateY(-90deg);
    transform-origin: 100% 50%;
    background-color: $color2;
  }
  &__front,
  &__back {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color3;
    background-color: $color1;
    width: 100%;
    height: 100%;
    font-size: calc($coin / 1.8);
    text-shadow: 0.2px 0.2px $color2, -0.2px 0 #fff;
    border-radius: 50%;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 50%;
      transform: scale(0.8);
      box-shadow: -0.4px -0.8px $color2, 0.4px 0.8px $color3;
    }
  }
  &__back {
    position: absolute;
    top: 0;
    transform: translateZ($depth * -1) rotateY(-180deg);
  }
  &__front-backface,
  &__back-backface {
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: $color2;
  }

  &__front-backface {
    transform: translateZ(-1px);
  }

  &__back-backface {
    transform: translateZ($depth * -1 + 1);
  }
}

@keyframes coin {
  0%,
  10% {
    transform: rotate(-10deg) perspective(400px);
  }
  90%,
  100% {
    transform: rotate(-10deg) perspective(400px) rotateY(180deg);
  }
  //   to {
  //     transform: rotateY(-359deg);
  //   }
}
